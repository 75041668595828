






























































































































































































































































































































































import Vue from 'vue';
import mixinsVue from './mixins.vue';
export default Vue.extend({
  'name': 'TermsAndConditions',
  'mixins': [mixinsVue],
  'props': {
    'warrantyType': {
      'type': Number,
      'default': 1
    }
  },
  'computed': {
    isBuilding () :boolean {
      return this.warrantyType === 1;
    }
  },
  'methods': {
    dismiss () {
      (this as any).hide();
    }
  }
});
